import React from 'react';

import styles from './CreateCampaignConfirmation.module.scss';
import { MultipartStepIndicator } from '../../components/Modal/ModalMultipartForm';
import ModalPopUp from '../../components/Modal/ModalPopUp';

const CreateCampaignConfirmation = ({isOpen, onClose, onConfirm, stepLabels, data}) => {
  const renderDescription = (data) => {
    return <div>
      {stepLabels &&
        <MultipartStepIndicator stepLabels={stepLabels} currentStep={stepLabels.length - 1}/>
      }
      <br/>
      <p>Campaign Summary</p>
      {data && (<div className={styles.summaryGroup}>
        {data['company'] && <div className={styles.record}>
          <label>Client</label>
          <p className={styles.data}>{data['company']}</p>
        </div>}
        <div className={styles.record}>
          <label>Campaign Name</label>
          <p className={styles.data}>{data['campaign_name']}</p>
        </div>
        {data['campaign_type'] && <div className={styles.record}>
          <label>Campaign Type</label>
          <p className={styles.data}>
            {data['campaign_type'].charAt(0).toUpperCase() + data['campaign_type'].slice(1)}
          </p>
        </div>}
        <div className={styles.record}>
          <label>Segment ID</label>
          <p className={styles.data}>{data['list_id']}</p>
        </div>
        {data['cost_per_send'] !== undefined && data['cost_per_send'] !== 0 &&
        <div className={styles.record}>
          <label>Cost per send</label>
          <p className={styles.data}>£{data['cost_per_send']}</p>
        </div>}
        {data['suppression_period'] !== undefined && data['suppression_period'] !== 0 &&
        <div className={styles.record}>
          <label>Suppression Period</label>
          <p className={styles.data}>{data['suppression_period']} days</p>
        </div>}
        {data['control_split'] !== undefined && data['control_split'] !== 0 &&
        <div className={styles.record}>
          <label>Control Split</label>
          <p className={styles.data}>{data['control_split']} %</p>
        </div>}
        {data['daily_send_limit'] !== undefined && data['daily_send_limit'] !== 0 &&
        <div className={styles.record}>
          <label>Daily send limit</label>
          <p className={styles.data}>{data['daily_send_limit']} mails per day</p>
        </div>}
        <hr/>
        {'a_b_split' in data && data['a_b_split'] < 100 &&
          data['variant_a_name'] && <div className={styles.record}>
          <label>Variant A Name</label>
          <p className={styles.data}>{data['variant_a_name']}</p>
        </div>}
        {'a_b_split' in data && data['a_b_split'] < 100 && <div className={styles.record}>
          <label>Variant A Probability</label>
          <p className={styles.data}>{data['a_b_split']} %</p>
        </div>}
        <div className={styles.record}>
          <label>Letter Creative
            {'a_b_split' in data && data['a_b_split'] < 100 &&
              data['letter_path_a'] ? ' for Variant A' : ''}
          </label>
          <p className={styles.data}>{data['letter_path_a']}</p>
        </div>
        <div className={styles.record}>
          <label>Outer Envelope Creative
            {'a_b_split' in data && data['a_b_split'] < 100 &&
              data['envelope_path_a'] ? ' for Variant A' : ''}
          </label>
          <p className={styles.data}>{data['envelope_path_a']}</p>
        </div>
        {'a_b_split' in data && data['a_b_split'] < 100 && <hr/>}
        {'a_b_split' in data && data['a_b_split'] < 100 &&
          data['variant_b_name'] && <div className={styles.record}>
          <label>Variant B Name</label>
          <p className={styles.data}>{data['variant_b_name']}</p>
        </div>}
        {'a_b_split' in data && data['a_b_split'] < 100 && <div className={styles.record}>
          <label>Variant B Probability</label>
          <p className={styles.data}>{100 - data['a_b_split']} %</p>
        </div>}
        {'a_b_split' in data && data['a_b_split'] < 100 &&
          data['letter_path_b'] && <div className={styles.record}>
          <label>Letter Creative for Variant B</label>
          <p className={styles.data}>{data['letter_path_b']}</p>
        </div>}
        {'a_b_split' in data && data['a_b_split'] < 100 &&
          data['envelope_path_b'] && <div className={styles.record}>
          <label>Outer Envelope Creative for Variant B</label>
          <p className={styles.data}>{data['envelope_path_b']}</p>
        </div>}
        <hr/>
        <div className={styles.record}>
          <label>Activation Time (UTC)</label>
          <p className={styles.data}>{data['activation_time']}</p>
        </div>
        {data['data_source'] && <div className={styles.record}>
          <label>Data Source</label>
          <p className={styles.data}>
            {data['data_source'].charAt(0).toUpperCase() + data['data_source'].slice(1)}
          </p>
        </div>}
      </div>)}
    </div>;
  };

  return <ModalPopUp
    isOpen={isOpen}
    onClose={onClose}
    title={'New campaign'}
    description={renderDescription(data)}
  >
    <div className={styles.buttonContainer}>
      <button className={styles.formButton} onClick={onClose}>PREVIOUS</button>
      <button className={styles.formButton} onClick={onConfirm}>CONFIRM</button>
    </div>
  </ModalPopUp>;
};

export default CreateCampaignConfirmation;
